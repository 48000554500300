body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiContainer-root {
    min-width: unset;
}

.MuiAccordionSummary-root {
    flex-direction: unset;
}

.MuiPickersToolbar-root {
    background-color: #5780EA;
}

.MuiPickersBasePicker-pickerView {
    background-color: #FFFFFF;
}

.MuiPickersToolbar-dateTitleContainer {
    align-items: baseline;
}

.MuiPickersDateRangePickerToolbarProps-dateTextContainer > .MuiTypography-colorPrimary {
    color: #FFFFFF;
}

.MuiPickersDateRangePickerToolbarProps-dateTextContainer .MuiButton-textPrimary:hover .MuiPickersToolbarText-root {
    color: #5780EA;
}

.MuiPickersToolbar-dateTitleContainer > .MuiTypography-colorPrimary {
    color: #FFFFFF;
}

.MuiPickersTimePickerToolbar-hourMinuteLabel .MuiButton-textPrimary:hover .MuiPickersToolbarText-root {
    color: #5780EA;
}

.MuiPickersClockNumber-clockNumberSelected .MuiTypography-colorPrimary {
    color: #FFFFFF;
    background-color: #5780EA;
}

.MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
    border-top-left-radius: 0;
}

.MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:last-child {
    border-top-right-radius: 0;
}

.MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
    background-color: #5780EA;
}

.MuiPickersBasePicker-pickerView {
    background-color: #5780EA;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.MuiPickersCalendar-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.MuiPickersDay-root.Mui-selected {
    background-color: #5780EA;
}

.MuiPickersCalendar-week {
    align-items: center;
}

fieldset:disabled {
    opacity: 0.75;
}

